import axios from 'axios'
import storage from 'store2'
import { VueAxios } from './axios'
import config from '@/config/config'
import { MessageBox } from "element-ui"
import router from '@/router'
import { clearStorage } from "@/utils/utils";

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: config.api_url,
  timeout: 60000, // 请求超时时间
  //headers: {'Content-Type': 'multipart/form-data'}
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    if (error.response.status === 403) {
      MessageBox.alert({
        title: 'Forbidden',
        message: data.message,
        icon: 'error'
      })
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config2 => {
  const loginInfo = localStorage.getItem('loginInfo')
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (loginInfo) {
    const info = JSON.parse(loginInfo)
    config2.headers['token'] = info.token
  }
  config2.headers['platform'] = 'pc'
  config2.headers['version'] = config.client_version
  return config2
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if (response.data.code === 10003) {
    clearStorage()
    router.push('/user/login')
    return
  }
  if (response.data.code === 410) {
    MessageBox.confirm('版本过期，请点击确定重载版本', '版本过期', {
      confirmButtonText: '重载',
      showCancelButton: false
    }).then(() => {
      window.location.reload()
    })
  }
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
