<template>
  <el-container id="app-layout-sider">
    <el-aside
      v-model="collapsed"
      theme="light"
      class="layout-sider"
      width="65px"
      style="overflow: hidden"
    >
      <div class="logo">
        <img
          class="pic-logo"
          :src="thumb(user.thumb_avatar || '/statics/default/user_avatar.png')"
          @click="goSetting"
        >
        <div style="font-weight: bold; font-size: 14px; color: red;padding: 0px 0 5px;">{{this.appName}}</div>
        <div style="font-weight: bold; font-size: 9px; color: blue;padding: 0px 0 10px;" v-if="1 == 2">{{user.userId}}</div>
      </div>
      <el-menu
        class="menu-item"
        theme="light"
        mode="inline"
        :collapse="true"
        :default-selected-keys="[default_key]"
        :default-active="default_key"
        style="border: 0"
        @select="menuHandle"
      >
        <el-menu-item
          v-for="(menuInfo, index) in menu"
          :key="index"
          :index="index"
        >
          <el-badge class="badge-a" v-if="index == 'menu_2' && friendApplyUnread > 0" :value="friendApplyUnread" :max="99">
            <i :class="menuInfo.icon" style="font-size: 23px"></i>
          </el-badge>
          <i v-else :class="menuInfo.icon" style="font-size: 23px"></i>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <keep-alive :include="['ChatList', 'Manage', 'Setting']">
        <router-view  />
      </keep-alive>
    </el-container>
  </el-container>
</template>
<script>
import {
  mapState
} from 'vuex'
import { thumb } from '@/utils/utils'
import { ipcApiRoute } from '@/api/main'

export default {
  name: 'AppSider',
  data() {
    return {
      collapsed: true,
      default_key: 'menu_1',
      current: '',
      appName: 'HChat',
      menu: {
        'menu_1' : {
          icon: 'el-icon-chat-dot-round',
          title: '会话',
          pageName: 'ChatList',
          params: {},
        },
        'menu_2' : {
          icon: 'el-icon-user',
          title: '好友',
          pageName: 'ContactList',
          params: {},
        },
        // 'menu_3' : {
        //   icon: 'el-icon-s-custom',
        //   title: '群组',
        //   pageName: 'GroupList',
        //   params: {},
        // },
        'menu_4' : {
          icon: 'el-icon-menu',
          title: '管理',
          pageName: 'Manage',
          params: {},
        },
        'menu_5' : {
          icon: 'el-icon-setting',
          title: '设置',
          pageName: 'Setting',
          params: {},
        },
        'menu_7' : {
          icon: 'el-icon-refresh',
          title: '刷新',
          pageName: 'Reload',
          params: {},
        },
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.yeim.user,
      loginInfo: state => state.yeim.loginInfo,
      friendApplyUnread: state => state.yeim.friendApplyUnread
    })
  },
  created () {
    try {
      this.$ipc.invoke(ipcApiRoute.test, '异步-回调').then(r => {
        if (r.name) {
          this.appName = r.name.replace('HuobiChat', 'HChat')
        }
      });
    } catch (err) {

    }
  },
  mounted () {
    this.menuHandle()
  },
  watch: {
    $route(to,from){
      if (to.name && to.name == 'ChatList') {
        this.current = 'menu_1'
        this.default_key = 'menu_1'
      }
    }
  },
  methods: {
    thumb,
    menuHandle (e, a) {
      if (this.user && (this.user.username == '' || this.user.remark_nickname == '')) {
        this.$router.push({ name: 'Setting', params: {active: 'person'}})
        return;
      }
      // console.log(e, a)
      // console.log(this.default_key)
      if (e == 'menu_6') {
        this.$store.state.showBot = !this.$store.state.showBot
        return;
      }
      if (e == 'menu_7') {
        window.location.reload()
        return;
      }
      this.current = e ? e : this.default_key;
      this.default_key = this.current
      const linkInfo = this.menu[this.current]
      // console.log('[home] load page:', linkInfo.pageName);
      this.$router.push({ name: linkInfo.pageName, params: linkInfo.params})
    },
    goSetting() {
      this.$router.push({ name: 'Setting', params: {active: 'person'}})
    }
  },
};
</script>
<style lang="less" scoped>
// 嵌套
#app-layout-sider {
  height: calc(100vh);
  .logo {
    border-bottom: 1px solid #e8e8e8;
  }
  .pic-logo {
    width: 50px;
    height: 50px;
    //background: rgba(139, 137, 137, 0.2);
    margin: 10px 10px 0px 10px;
    border-radius: 4px;
  }
  .layout-sider {
    border-right: 1px solid #e8e8e8;
    text-align: center;
  }
  .menu-item {
    .ant-menu-item {
      background-color: #fff;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0 0px !important;
    }
  }
  .layout-content {
    //background-color: #fff;
  }
}
</style>
