/**
 * 子菜单
 */
export default {
    framework: {
        'menu_100': {
            icon: 'profile',
            title: '消息',
            pageName: 'FrameworkSocketIpc',
            params: {}
        },
        'menu_101': {
            icon: 'profile',
            title: '好友',
            pageName: 'FrameworkSocketHttpServer',
            params: {}
        },
        'menu_102': {
            icon: 'profile',
            title: '群聊',
            pageName: 'FrameworkSocketSocketServer',
            params: {}
        },
        'menu_103': {
            icon: 'profile',
            title: '发现',
            pageName: 'FrameworkDBIndex',
            params: {}
        },
        'menu_104': {
            icon: 'profile',
            title: '设置',
            pageName: 'FrameworkSqliteDBIndex',
            params: {}
        },
        'menu_105': {
            icon: 'profile',
            title: '任务',
            pageName: 'FrameworkJobsIndex',
            params: {}
        },
        'menu_106': {
            icon: 'profile',
            title: '自动更新',
            pageName: 'FrameworkUpdaterIndex',
            params: {}
        },
        'menu_107': {
            icon: 'profile',
            title: '软件调用',
            pageName: 'FrameworkSoftwareIndex',
            params: {}
        },
        'menu_108': {
            icon: 'profile',
            title: 'java',
            pageName: 'FrameworkJavaIndex',
            params: {}
        },
        'menu_109': {
            icon: 'profile',
            title: '测试',
            pageName: 'FrameworkTestApiIndex',
            params: {}
        },
    },
    os: {
        'menu_100': {
            icon: 'profile',
            title: '文件',
            pageName: 'OsFileIndex',
            params: {}
        },
        'menu_101': {
            icon: 'profile',
            title: '视图',
            pageName: 'OsWindowViewIndex',
            params: {}
        },
        'menu_102': {
            icon: 'profile',
            title: '窗口',
            pageName: 'OsWindowIndex',
            params: {}
        },
        'menu_103': {
            icon: 'profile',
            title: '桌面通知',
            pageName: 'OsNotificationIndex',
            params: {}
        },
        'menu_104': {
            icon: 'profile',
            title: '电源监控',
            pageName: 'OsPowerMonitorIndex',
            params: {}
        },
        'menu_105': {
            icon: 'profile',
            title: '屏幕信息',
            pageName: 'OsScreenIndex',
            params: {}
        },
        'menu_106': {
            icon: 'profile',
            title: '系统主题',
            pageName: 'OsThemeIndex',
            params: {}
        },
    },
    hardware: {
        'menu_100': {
            icon: 'profile',
            title: '打印机',
            pageName: 'HardwarePrinterIndex',
            params: {}
        }
    },
    effect: {
        'menu_100': {
            icon: 'profile',
            title: '视频播放器',
            pageName: 'EffectVideoIndex',
            params: {}
        }
    }
}
