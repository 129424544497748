import router from '@/router'
export default {
    data() {
        return {
            isLogged: false,
            needCheckLogin: true,
            filerPath: ['/', '/user/login']
        }
    },
    created() {
        if (!this.$route || !this.$route.path) {
            return false;
        }
        if (this.filerPath.includes(this.$route.path)) {
            return false;
        }
        // console.log('登陆检测', localStorage.getItem('loginInfo'))
        this.isLogged = localStorage.getItem('loginInfo') !== null ? true : false;
        if (this.needCheckLogin === true && this.isLogged === false) {
            this.$_checkLogin()
        }
    },
    methods: {
        $_checkLogin() {
            this.isLogged = localStorage.getItem('loginInfo') ? true : false
            if (!this.isLogged) {
                // console.log('未登陆')
                this.$router.push({ name: 'UserLogin' })
                router.push({name: 'UserLogin'})
            }
            return this.isLogged
        }
    }
}
