let urls = window.location.hostname.split('.')
let url = window.location.hostname
if (urls.length > 2) {
    url = 'api.' + urls[1] + '.' + urls[2];
} else {
    url = 'api.' + urls[0] + '.' + urls[1];
}

let protocol = window.location.protocol
let ws = protocol == 'http:' ? 'ws' : 'wss'

const VUE_APP_API_URL = process.env.VUE_APP_API_BASE_URL || `${protocol}//${url}/api`
const VUE_APP_WS_URL = process.env.VUE_APP_SOCKET_URL || `${ws}:${url}/im`
const VUE_APP_CDN_URL = process.env.VUE_APP_CDN_URL || `${protocol}//${url}`

const config = {
    api_url: VUE_APP_API_URL,
    socket_url: VUE_APP_WS_URL,
    cdn_url: VUE_APP_CDN_URL,
    client_version: process.env.VUE_APP_VERSION,
    lang: process.env.VUE_APP_LANG,
    cdn_urls: [
        process.env.VUE_APP_CDN_URL_1 || VUE_APP_CDN_URL, process.env.VUE_APP_CDN_URL_2 || VUE_APP_CDN_URL,
        process.env.VUE_APP_CDN_URL_3 || VUE_APP_CDN_URL, process.env.VUE_APP_CDN_URL_4 || VUE_APP_CDN_URL,
        process.env.VUE_APP_CDN_URL_5 || VUE_APP_CDN_URL, process.env.VUE_APP_CDN_URL_6 || VUE_APP_CDN_URL,
        process.env.VUE_APP_CDN_URL_7 || VUE_APP_CDN_URL, process.env.VUE_APP_CDN_URL_8 || VUE_APP_CDN_URL
    ],
    cdn_url_config: [
        ['0', '8', 'g', 'o', 'w'],
        ['1', '9', 'h', 'p', 'x'],
        ['2', 'a', 'i', 'q', 'y'],
        ['3', 'b', 'j', 'r', 'z'],
        ['4', 'c', 'k', 's'],
        ['5', 'd', 'l', 't'],
        ['6', 'e', 'm', 'u'],
        ['7', 'f', 'n', 'v'],
    ],
    gift: [
        {
            start: '1000',
            middle: '1020',
            end: '1040',
            amount: 100,
            amount2: 50
        },
        {
            start: '1040',
            middle: '1100',
            end: '1120',
            amount: 150,
            amount2: 100
        },
        {
            start: '1120',
            middle: '1140',
            end: '1200',
            amount: 200,
            amount2: 150
        },
        {
            start: '1200',
            middle: '1230',
            end: '1300',
            amount: 250,
            amount2: 200
        },
        {
            start: '1300',
            middle: '1320',
            end: '1340',
            amount: 300,
            amount2: 250
        },
        {
            start: '1340',
            middle: '1400',
            end: '1420',
            amount: 350,
            amount2: 300
        },
        {
            start: '1420',
            middle: '1440',
            end: '1500',
            amount: 400,
            amount2: 300
        },
        {
            start: '1500',
            middle: '1530',
            end: '1600',
            amount: 450,
            amount2: 300
        },
        {
            start: '1600',
            middle: '1620',
            end: '1640',
            amount: 500,
            amount2: 300
        },
        {
            start: '1640',
            middle: '1700',
            end: '1720',
            amount: 550,
            amount2: 300
        },
        {
            start: '1720',
            middle: '1740',
            end: '1800',
            amount: 600,
            amount2: 300
        },
        {
            start: '1800',
            middle: '1830',
            end: '1900',
            amount: 650,
            amount2: 300
        },
        {
            start: '1900',
            middle: '1920',
            end: '1940',
            amount: 700,
            amount2: 300
        },
        {
            start: '1940',
            middle: '2000',
            end: '2020',
            amount: 750,
            amount2: 300
        },
        {
            start: '2020',
            middle: '2040',
            end: '2359',
            amount: 800,
            amount2: 300
        }
    ]
}
module.exports = config
