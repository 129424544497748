import {
    YeIMUniSDK,
    YeIMUniSDKDefines
} from "../utils/wzJun1-YeIM-Uni-SDK/js_sdk/yeim-uni-sdk.min";

import router from '@/router'
import { clearStorage } from "@/utils/utils";
import { MessageBox } from "element-ui";


export default {
    namespaced: true,
    state: {
        event: null,
        yeimLogin: false,
        connected: false,
        user: {}, // IM登录用户
        loginInfo: {},
        conversationList: [], // 会话列表
        subAccountList: [],
        groupList: [], //群组
        friendList: [], //好友列表
        friendApplyList: [], //好友申请列表
        friendApplyUnread: 0, //好友申请未读数,
        refreshTimer: 0
    },
    mutations: {
        setConversationList(state, res) {
            state.conversationList = res;
        }
    },
    actions: {
        reset({
                  commit,
                  state,
                  dispatch
              }, res) {
            // 连接状态
            YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.RELOAD, (list) => {
                console.log("重载")
                MessageBox.confirm('版本过期，请点击确定重载版本', '版本过期', {
                    confirmButtonText: '重载',
                    showCancelButton: false
                }).then(() => {
                    window.location.reload()
                })
            })
            YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.CONNECTING, (list) => {
                console.log("连接中:")
                state.connected = false
                if (state.refreshTimer > 0) {
                    clearInterval(state.refreshTimer)
                    state.refreshTimer = 0
                }
            })
            YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.CONNECTED, (list) => {
                console.log("连接成功:")
                state.connected = true
                if (state.refreshTimer == 0) {
                    state.refreshTimer = setInterval(function () {
                        if (YeIMUniSDK.getInstance().socketLogged) {
                            YeIMUniSDK.getInstance().saveCloudConversationListToLocal(1, 10000)
                        }
                    }, 30000)
                }
            })
            // 会话列表变化
            YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.CONVERSATION_LIST_CHANGED, (list) => {
                console.log("收到会话列表变化变化事件:")
                if (list && list) {
                    list.forEach((item) => {
                        if (item.userInfo) {
                            localStorage.setItem('yeim:friend_user_info_' + item.friend_user_id, JSON.stringify(item.userInfo))
                        }
                    })
                    commit('setConversationList', JSON.parse(JSON.stringify(list)));
                }
            })
            //好友列表变化
            YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.FRIEND_LIST_CHANGED, (res) => {
                console.log("收到好友列表变化变化事件:")
                if (res && res.data) {
                    state.friendList = JSON.parse(JSON.stringify(res.data));
                }
            })
            
            //子账号变化
            YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.SUB_ACCOUNT_LIST_CHANGED, (res) => {
                console.log("收到子账号列表变化变化事件:")
                state.subAccountList = []
                if (res && res.length > 0) {
                    state.subAccountList = res
                }
            })
            
            //好友申请列表变化
            YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.FRIEND_APPLY_LIST_CHANGED, (res) => {
                console.log("收到好友申请列表变化事件:", res)
                if (res && res.data) {
                    state.friendApplyUnread = res.data.unread;
                    state.friendApplyList = JSON.parse(JSON.stringify(res.data.data));
                }
            });
    
            //群组列表变化
            YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.GROUP_LIST_CHANGED, (res) => {
                console.log("收到群组列表变化事件:", res)
                if (res && res.data) {
                    state.groupList = JSON.parse(JSON.stringify(res.data));
                }
            });
            
            //断开连接
            YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.KICKED_OUT, () => {
                console.log("被踢下线了:")
                clearStorage()
                router.push({ name: 'UserLogin' })
            })
    
            //如果有已经登陆的缓存，则直接重新登陆
            let user = localStorage.getItem('user');
            let loginInfo = localStorage.getItem('loginInfo');
            if (user && loginInfo) {
                user = JSON.parse(user)
                loginInfo = JSON.parse(loginInfo)
                dispatch('login', {
                    userId: user.user_id,
                    token: loginInfo.token,
                    timestamp: loginInfo.expireTime
                });
            }
        },
        // 登录后处理
        login({
                  state,
                  dispatch
              }, res) {
            YeIMUniSDK.getInstance().connect({
                userId: res.userId,
                token: res.token,
                success: (response) => {
                    if (response.code === 200) {
                        // 存到状态中
                        state.user = response.data;
                        state.loginInfo = {
                            expireTime: res.timestamp,
                            token: res.token
                        };
                        localStorage.setItem('user', JSON.stringify(state.user));
                        localStorage.setItem('loginInfo', JSON.stringify(state.loginInfo))
                        
                        state.yeimLogin = true;
                        
                        if (state.user.username == '' || state.user.remark_nickname == '') {
                            router.push({ name: 'Setting', params: {active: 'person'}})
                        } else {
                            router.push('/chat/list')
                        }
                        
                        // window.location.href = '#/chat/list'
                        console.log("connect success")
                    } else {
                        console.log("connect error")
                        clearStorage()
                        state.yeimLogin = false;
                    }
                },
                fail: (err) => {
                    console.log("connect error")
                    clearStorage()
                    router.push('/user/login')
                }
            });
        },
        // 退出登录
        logout({
                   state,
                   dispatch
               }, res) {
            if (state.refreshTimer > 0) {
                clearInterval(state.refreshTimer)
                state.refreshTimer = 0
            }
            YeIMUniSDK.getInstance().disConnect();
            state.user = null;
            state.loginInfo = null;
            state.yeimLogin = false;
            clearStorage()
            // uni.clearStorageSync();
            // uni.reLaunch({
            //   url: '/pages/user/login'
            // })
        },
        // 更新用户资料
        flushUserInfo({
                          state,
                          dispatch
                      }, res) {
            YeIMUniSDK.getInstance().getUserInfo({
                userId: state.user.userId,
                cloud: true,
                success: (res) => {
                    if (res.data) {
                        state.user = res.data;
                    }
                },
                fail: (err) => {
                    console.log(err)
                }
            });
        },
    }
}
