import config from '@/config/config'
import request from '@/utils/request'
import md5 from "js-md5";

function dateTimeFormat(date, onlyTime) {
    //date = date +
    //  auto 0
    const autoZero = (n) => (String(n).length === 1 ? '0' : '') + n
    // string to timestamp
    const strToTimestamp = (str) => Date.parse(str.replace(/-/gi, '/'))
    let oriSecond = date
    let curSecond = parseInt(new Date().getTime())
    let diffSecond = (curSecond - oriSecond) / 1000
    let curDate = new Date(curSecond)
    let oriDate = new Date(oriSecond)
    
    let Y = oriDate.getFullYear()
    let m = oriDate.getMonth() + 1
    let d = oriDate.getDate()
    let H = oriDate.getHours()
    let i = oriDate.getMinutes()
    
    // just
    if (diffSecond < 60) {
        // within a minute
        if (onlyTime) {
            return `${autoZero(H)}:${autoZero(i)}`
        }
        return '刚刚'
    } else if (diffSecond < 3600) {
        // within an hour
        if (onlyTime) {
            return `${autoZero(H)}:${autoZero(i)}`
        }
        return `${Math.floor(diffSecond / 60)}分钟前`
    } else if (
        curDate.getFullYear() === Y &&
        curDate.getMonth() + 1 === m &&
        curDate.getDate() === d
    ) {
        return `${autoZero(H)}:${autoZero(i)}`
    }
    // yesterday
    let mewDate = new Date((curSecond - 86400))
    if (mewDate.getFullYear() === Y && mewDate.getMonth() + 1 === m && mewDate.getDate() === d) {
        return `昨天${autoZero(H)}:${autoZero(i)}`
    } else if (curDate.getFullYear() === Y) {
        if (onlyTime) {
            return `${autoZero(m)}-${autoZero(d)} ${autoZero(H)}:${autoZero(i)}`
        }
        return `${autoZero(m)}-${autoZero(d)}`
    }
    // return `${Y}年${autoZero(m)}月${autoZero(d)}日`
    if (onlyTime) {
        return `${autoZero(m)}-${autoZero(d)} ${autoZero(H)}:${autoZero(i)}`
    }
    return `${autoZero(m)}-${autoZero(d)}`
}

function coverTime(date) {
    const autoZero = (n) => (String(n).length === 1 ? '0' : '') + n
    
    let Y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    let H = date.getHours()
    let i = date.getMinutes()
    
    return `${autoZero(Y)}-${autoZero(m)}-${autoZero(d)} ${autoZero(H)}:${autoZero(i)}`
}

function parseContent(item) {
    let content = '';
    switch (item.lastMessage.type) {
        case YeIMUniSDKDefines.MESSAGE_TYPE.TEXT:
            content = item.lastMessage.body.text;
            break;
        case YeIMUniSDKDefines.MESSAGE_TYPE.IMAGE:
            content = '[图片]';
            break;
        case YeIMUniSDKDefines.MESSAGE_TYPE.AUDIO:
            content = '[语音消息]';
            break;
        case YeIMUniSDKDefines.MESSAGE_TYPE.VIDEO:
            content = '[视频消息]';
            break;
        case YeIMUniSDKDefines.MESSAGE_TYPE.LOCATION:
            content = '[位置消息]';
            break;
        default:
            content = '[其他消息类型]';
    }
    return content;
}

function getGift(date, level) {
    let oriDate = new Date(date)
    
    let t = parseInt(oriDate.toTimeString().substr(0, 5).replace(':', ''))
    
    let reArr = [];
    let cur = 0
    let isPre = false
    let inTime = false
    level = level || 2
    config.gift.forEach((item, idx) => {
        if (parseInt(item.start) <= t && t < parseInt(item.end)) {
            reArr.push(level == 1 ? item.amount2 : item.amount)
            cur = idx
            inTime = true
            if (parseInt(item.start) <= t && t <= parseInt(item.middle)) {
                isPre = true
            }
        }
    })
    if (isPre && cur > 0) {
        reArr.unshift(level == 1 ? config.gift[cur - 1].amount2 : config.gift[cur - 1].amount)
    } else if (config.gift[cur + 1]) {
        if (inTime) {
            reArr.push(level == 1 ? config.gift[cur + 1].amount2 : config.gift[cur + 1].amount)
        } else {
            reArr.push(level == 1 ? config.gift[cur].amount2 : config.gift[cur].amount)
        }
    }
    
    return reArr
}

function fileToBlob(file, callback) {
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
        let blob = null;
        if (typeof e.target.result === 'object') {
            blob = new Blob([e.target.result])
        } else {
            blob = e.target.result
        }
        callback(blob)
    }
}

function blobToBase64(blob, callback) {
    var reader = new FileReader()
    reader.onload = function () {
        var dataUrl = reader.result
        callback(dataUrl)
    }
    reader.readAsDataURL(blob)
};

function saveAs(file) {
    request(file).then(res => res.blob().then(blob => {
        var url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        const fileName = file.split('/')
        link.setAttribute('download', fileName[fileName.length - 1]);
        document.body.appendChild(link);
        link.click();
        
        window.URL.revokeObjectURL(url);//释放url对象所占资源
        document.body.removeChild(link);//用完删除
    }))
};

export function getImageInfo(file, callback) {
    const reader = new FileReader();
    reader.onload = function () {
        const img = new Image();
        img.src = reader.result;
        img.onload = function () {
            callback({
                width: img.width,
                height: img.height
            })
        };
    }
    reader.readAsDataURL(file);
};

function downloadImage(imgSrc) {
    // imgSrc：图片地址，name：图片名称
    const fileName = imgSrc.split('/')
    let image = new Image();
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        let a = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = fileName[fileName.length - 1]; //
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = imgSrc + '?v=' + Math.random();
};

function thumb(url, w, h) {
    if (!url) {
        return 'assets/image/default/user_avatar.png'
    }
    
    if (typeof url == 'object') {
        // type 有多种取值
        const blob = new Blob([url], { type: 'application/pdf' })
        return window.URL.createObjectURL(blob)
    }
    
    if (url.indexOf(';base64,') !== -1) {
        return url
    }
    
    if (url.indexOf('http') === 0) {
        return url
    }
    
    const s = md5(url).toLowerCase().substr(0, 1)
    
    let si = 0;
    config.cdn_url_config.forEach((item, idx) => {
        if (item.includes(s)) {
            si = idx
        }
    })
    
    return config.cdn_urls[si] + url
}

function clearStorage() {
    const length = localStorage.length
    let keys = []
    for (let i = 0; i < length; i++) {
        if (localStorage.key(i) != 'remember_username' && localStorage.key(i) != 'remember_password') {
            keys.push(localStorage.key(i))
        }
    }
    for (let j = 0; j < keys.length; j++) {
        localStorage.removeItem(keys[j])
    }
}

function getCurrentDay()
{
    const autoZero = (n) => (String(n).length === 1 ? '0' : '') + n
    const date = new Date();
    
    return date.getFullYear() + '-' + autoZero(date.getMonth() + 1) + '-' + autoZero(date.getDate());
}

function getCurrentMonth()
{
    const autoZero = (n) => (String(n).length === 1 ? '0' : '') + n
    const date = new Date();
    
    return date.getFullYear() + '-' + autoZero(date.getMonth() + 1) ;
}

export {
    dateTimeFormat,
    coverTime,
    parseContent,
    blobToBase64,
    fileToBlob,
    saveAs,
    downloadImage,
    getGift,
    thumb,
    clearStorage,
    getCurrentDay,
    getCurrentMonth
}
