/**
 * 基础路由
 * @type { *[] }
 */
import {AppSider, Menu} from '@/layouts'

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

export const constantRouterMap = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'UserLogin' }
  },
  {
    path: '/user/login',
    name: 'UserLogin',
    component: () => import('@/views/user/login'),
  },
  {
    path: '/chat',
    component: AppSider,
    children: [
      {
        path: '/chat/list',
        name: 'ChatList',
        component: () => import('@/views/chat/list')
      },
      {
        path: '/chat/user-list',
        name: 'ChatUserList',
        component: () => import('@/views/chat/user-list')
      }
    ]
  },
  {
    path: '/contact',
    component: AppSider,
    children: [
      {
        path: '/contact/list',
        name: 'ContactList',
        component: () => import('@/views/contact/list')
      }
    ]
  },
  {
    path: '/manage',
    component: AppSider,
    children: [
      {
        path: '/manage/index',
        name: 'Manage',
        component: () => import('@/views/manage/index')
      }
    ]
  },
  {
    path: '/setting',
    component: AppSider,
    children: [
      {
        path: '/setting/index',
        name: 'Setting',
        component: () => import('@/views/setting/index')
      }
    ]
  }
]
