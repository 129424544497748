import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n

import App from './App'
import router from './router'
import store from './store';
import { VueAxios } from './utils/request'
import { ipc } from '@/utils/ipcRenderer'
import config from '@/config/config'

import {
    YeIMUniSDK,
    YeIMUniSDKDefines
} from './utils/wzJun1-YeIM-Uni-SDK/js_sdk/yeim-uni-sdk.min.js'

Vue.prototype.$store = store
Vue.config.productionTip = false
App.mpType = 'app'

// 移动端滚动插件
import vueScroll from 'vuescroll';
import 'vuescroll/dist/vuescroll.css'
import happyScroll from 'vue-happy-scroll'
import 'vue-happy-scroll/docs/happy-scroll.css'

Vue.use(vueScroll);
Vue.use(happyScroll);

Vue.use(ElementUI, { locale });

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)

// 右键菜单
import Contextmenu from "vue-contextmenujs"
Vue.use(Contextmenu);

// 全局注入IPC通信
Vue.prototype.$ipc = ipc

Vue.config.productionTip = false

/**
 * @description 全局注册应用配置
 */
Vue.prototype.$config = config;

import CheckLoginMixin from '@/mixins/CheckLoginMixin.js'

//初始化YeIMUniSDK
YeIMUniSDK.init({
    baseURL: config.api_url, // YeIMServer http url （如无特殊需求，服务端启动后仅需修改ip或者域名即可）
    socketURL: config.socket_url, // YeIMServer socket url（如无特殊需求，服务端启动后仅需修改ip或者域名即可）
    connectFrom: 'pc',
    clientVersion: config.client_version,
    lang: config.lang,
    /**
     *    日志等级
     *  0 普通日志，日志量较多，接入时建议使用
     *    1 关键性日志，日志量较少，生产环境时建议使用 '//'[;]
     *    2 无日志级别，SDK 将不打印任何日志
     */
    logLevel: 0, // 日志等级，
    reConnectInterval: 3000, // 重连时间间隔
    reConnectTotal: 0, // 最大重连次数，0不限制一直重连
    heartInterval: 20000, //心跳时间间隔(默认30s)
});

const app = new Vue({
    store,
    router,
    render: h => h(App)
})

Vue.mixin(CheckLoginMixin)

app.$mount('#app')
