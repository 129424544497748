import Vue from 'vue'
import Vuex from 'vuex'
import yeim from '@/store/yeim.js';
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		showBot: false
	},
	modules: {
		yeim
	}
})
